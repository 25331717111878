import { DateField } from "@mui/x-date-pickers";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { CryptorFormData } from "../utils/cryptor.schema";

interface CryptorDeathDateFieldProps {
  control: Control<CryptorFormData, {}>;
}

const CryptorDeathDateField: FC<CryptorDeathDateFieldProps> = ({ control }) => {
  return (
    <Controller
      control={control}
      name="deathDate"
      render={({ field }) => (
        <DateField
          label="Date de décès"
          value={field.value}
          onChange={field.onChange}
          margin="normal"
          minDate={new Date("0000-01-01")}
        />
      )}
    />
  );
};

export default CryptorDeathDateField;
