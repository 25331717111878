import React, { FC, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Stack, Input, Typography, Box, IconButton } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AudioPlayer from "../miscellaneous/AudioPlayer";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";

interface UploadButtonProps {
  id?: string;
  inputSrc: string;
  label?: string;
  labelWidth?: number;
  type?: "audio" | "image";
  uploadButtonLabel?: string;
  onError: (error: unknown) => void;
  errorMessage: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  onDelete?: () => void;
}

const UploadButton: FC<UploadButtonProps> = ({
  id = "file",
  inputSrc,
  label,
  labelWidth,
  type = "image",
  uploadButtonLabel = "Upload",
  onError,
  errorMessage,
  onChange,
  onDelete,
}) => {
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const onChangeHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      setIsUploadLoading(true);
      await onChange(event);
      setIsUploadLoading(false);
    } catch (error) {
      onError(error);
      setIsUploadLoading(false);
    }
  };

  return (
    <Stack
      direction="row"
      gap={4}
      flexWrap="wrap"
      alignItems="center"
      sx={{ marginTop: "16px" }}
    >
      {type === "image" && (
        <Box sx={{ maxWidth: 150, position: "relative" }}>
          {onDelete && inputSrc && (
            <IconButton
              aria-label="delete"
              sx={{ position: "absolute", right: 0 }}
              onClick={onDelete}
            >
              <CloseIcon />
            </IconButton>
          )}
          {inputSrc ? (
            <Box
              component="img"
              sx={{ width: "100%", height: "100%" }}
              src={inputSrc}
              alt=""
            />
          ) : (
            <ImageIcon sx={{ width: "100%", height: "100%" }} />
          )}
        </Box>
      )}
      {inputSrc && type === "audio" && (
        <Box alignItems="center" display="flex" gap="5px">
          <AudioPlayer url={inputSrc} />
          {onDelete && (
            <IconButton aria-label="delete" onClick={onDelete}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      )}
      {label && (
        <Typography variant="body1" width={labelWidth}>
          {label}
        </Typography>
      )}
      <label htmlFor={id + "images-input"}>
        <Input
          id={id + "images-input"}
          type="file"
          sx={{ display: "none" }}
          onChange={onChangeHandler}
        />
        <input id={id} type="hidden" />
        <LoadingButton
          loading={isUploadLoading}
          loadingPosition="end"
          endIcon={<FileUploadIcon />}
          variant="contained"
          component="span"
        >
          {uploadButtonLabel}
        </LoadingButton>
        <br />
        {errorMessage && (
          <Typography variant="caption" color="#fa541c">
            {errorMessage}
          </Typography>
        )}
      </label>
    </Stack>
  );
};

export default UploadButton;
