import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";
import { FC } from "react";

interface StyledSelectProps {
  label: string;
  selectProps: any;
  errorMessage: string;
  children: React.ReactNode;
}

const StyledSelect: FC<StyledSelectProps> = ({
  label,
  selectProps,
  errorMessage,
  children,
}) => {
  return (
    <FormControl sx={{ marginTop: 1 }}>
      <InputLabel htmlFor="outlined-adornment" error={!!errorMessage}>
        {label}
      </InputLabel>
      <Select {...selectProps} label="Ville" error={!!errorMessage}>
        {children}
      </Select>
      <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default StyledSelect;
