import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./i18n";

Sentry.init({
  dsn: "https://a887cac58a700ed9cc99daed8b1599b8@o4505832230748160.ingest.sentry.io/4506077676175360",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
