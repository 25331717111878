import React, { useMemo } from "react";
import {
  useDataGrid,
  List,
  DeleteButton,
  EditButton,
  ShowButton,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps } from "@refinedev/core";

export const AdvertisementList: React.FC<IResourceComponentsProps> = () => {
  const { dataGridProps } = useDataGrid({
    pagination: { mode: "client" },
    sorters: { mode: "off" },
    filters: { mode: "off" },
  });

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "url",
        flex: 1,
        headerName: "Url",
        minWidth: 200,
      },
      {
        field: "image.url",
        headerName: "Image",
        minWidth: 50,
        valueGetter: (params) => params.row?.image?.url,
        renderCell: function render({ value }) {
          return <img src={value} alt="Badge" width="50" />;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
              <ShowButton hideText recordItemId={row.id} />
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        getRowId={(row) => row.id}
      />
    </List>
  );
};
