import { Edit } from "@refinedev/mui";
import { Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps } from "@refinedev/core";
import UploadButton from "../../components/forms/UploadButton";
import { Controller, FieldValues } from "react-hook-form";
import { upload } from "../../utils/upload";
import { removeQueryParams } from "../../utils/parseUrl";

export const AvatarEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    refineCore: { formLoading, onFinish },
    register,
    formState: { errors, isValid, isDirty },
    setError,
    control,
    handleSubmit,
  } = useForm();

  const onFinishHandler = (data: FieldValues) => {
    onFinish({
      image: { url: removeQueryParams(data.image.url) },
    });
  };

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={{
        onClick: handleSubmit(onFinishHandler),
        disabled: !isDirty || !isValid,
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("id", {
            required: "Ce champ est requis",
            valueAsNumber: true,
          })}
          defaultValue={null}
          error={!!(errors as any)?.id}
          helperText={(errors as any)?.id?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          label="Id"
          name="id"
          disabled
        />
        <Controller
          control={control}
          name="image.url"
          defaultValue={null}
          rules={{ required: "Ce champ est requis" }}
          render={({ field }) => (
            <UploadButton
              inputSrc={field.value}
              label="Image"
              onError={() =>
                setError("image.url", {
                  message: "L'upload a échoué. Veuillez réessayer",
                })
              }
              errorMessage={(errors as any)?.image?.url?.message}
              onChange={async (event) => {
                const url = await upload(event, "avatars");
                field.onChange(url);
              }}
            />
          )}
        />
      </Box>
    </Edit>
  );
};
