import { Create } from "@refinedev/mui";
import { Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps } from "@refinedev/core";
import { Controller, FieldValues } from "react-hook-form";
import UploadButton from "../../components/forms/UploadButton";
import { upload } from "../../utils/upload";
import { getTranslation } from "../../utils/translation";
import { Level } from "shared";
import { useEffect } from "react";

interface LevelCreateEditProps extends IResourceComponentsProps {
  isEditing?: boolean;
}

export const LevelCreateEdit: React.FC<LevelCreateEditProps> = ({
  isEditing = false,
}) => {
  const {
    refineCore: { formLoading, onFinish, queryResult },
    register,
    formState: { errors, isValid, isDirty },
    control,
    handleSubmit,
    setError,
    setValue,
  } = useForm();

  const data = queryResult?.data?.data as Level;

  useEffect(() => {
    if (!data) return;
    setValue("name", getTranslation(data.nameTranslation)?.value);
  }, [data, setValue]);

  const onFinishHandler = (data: FieldValues) => {
    onFinish({
      level: !isEditing ? data.level : undefined,
      pointsRequired: data.pointsRequired,
      image: { url: data.image.url },
      nameTranslation: { value: data.name },
    });
  };

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        onClick: handleSubmit(onFinishHandler),
        disabled: !isDirty || !isValid,
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("level", {
            required: "Ce champ est requis",
            valueAsNumber: true,
          })}
          defaultValue={null}
          error={!!(errors as any)?.level}
          helperText={(errors as any)?.level?.message}
          margin="normal"
          fullWidth
          type="number"
          InputLabelProps={{ shrink: true }}
          label="Niveau"
          name="level"
          disabled={isEditing}
        />
        <TextField
          {...register("name", {
            required: "Ce champ est requis",
          })}
          defaultValue={null}
          error={!!(errors as any)?.name}
          helperText={(errors as any)?.name?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Nom"
          name="name"
        />
        <TextField
          {...register("pointsRequired", {
            required: "Ce champ est requis",
            valueAsNumber: true,
          })}
          defaultValue={null}
          error={!!(errors as any)?.pointsRequired}
          helperText={(errors as any)?.pointsRequired?.message}
          margin="normal"
          fullWidth
          type="number"
          InputLabelProps={{ shrink: true }}
          label="Points requis"
          name="pointsRequired"
        />
        <Controller
          control={control}
          name="image.url"
          defaultValue={null}
          rules={{ required: "Ce champ est requis" }}
          render={({ field }) => (
            <UploadButton
              id="image"
              inputSrc={field.value}
              uploadButtonLabel="Upload badge"
              onError={() =>
                setError("image.url", {
                  message: "L'upload a échoué. Veuillez réessayer",
                })
              }
              onChange={async (event) => {
                const url = await upload(event, "badges");
                field.onChange(url);
              }}
              errorMessage={(errors as any)?.image?.url?.message}
            />
          )}
        />
      </Box>
    </Create>
  );
};
