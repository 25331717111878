import { Typography, Box, Divider, IconButton, Stack } from "@mui/material";
import { getTranslation } from "../../../utils/translation";
import { Artefact } from "../../../../../backend/shared/types/artefact/artefact.type";
import { useGo } from "@refinedev/core";
import { FC } from "react";
import { TextFieldComponent as TextField } from "@refinedev/mui";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface CryptorArtefactShowProps {
  title: string;
  artefact: Artefact | null;
}

const CryptorArtefactShow: FC<CryptorArtefactShowProps> = ({
  title,
  artefact,
}) => {
  const go = useGo();
  const { id } = useParams();
  return (
    <>
      <Divider sx={{ marginTop: 3 }} />
      <Typography variant="body1" fontWeight="bold">
        {title}
      </Typography>
      {artefact ? (
        <Box>
          <Typography variant="body2" fontWeight="bold">
            Titre de la pop-up
          </Typography>
          <TextField
            value={getTranslation(artefact?.titlePopUpTranslation)?.value}
          />
          <Typography variant="body2" fontWeight="bold">
            Texte de la pop-up
          </Typography>
          <TextField
            value={getTranslation(artefact?.textPopUpTranslation)?.value}
          />
          <Typography variant="body2" fontWeight="bold">
            Récompense
          </Typography>
          <TextField value={artefact?.reward} />
          <Typography variant="body2" fontWeight="bold">
            Image
          </Typography>
          <img src={artefact?.image?.url} alt="Badge" width="100" />
          {artefact?.chatMessageId && (
            <Stack
              marginTop={1}
              marginBottom={1}
              direction="row"
              alignItems="center"
            >
              <Typography variant="body1" fontWeight="bold">
                Chat message
              </Typography>
              <IconButton
                onClick={() => {
                  go({
                    to: `/cryptors/${id}/chat-message/${artefact.chatMessageId}`,
                  });
                }}
              >
                <OpenInNewIcon />
              </IconButton>
            </Stack>
          )}
        </Box>
      ) : (
        <ClearIcon />
      )}
    </>
  );
};

export default CryptorArtefactShow;
