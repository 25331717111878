import { Stack, TextField } from "@mui/material";
import { FC } from "react";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";

interface QuestionRewardProps {
  control: Control<FieldValues, {}>;
  errors: FieldErrors<FieldValues>;
  textName: string;
  textLabel?: string;
  rewardName: string;
}

const QuestionReward: FC<QuestionRewardProps> = ({
  control,
  errors,
  textName,
  textLabel = "Question",
  rewardName,
}) => {
  return (
    <Stack direction="row" gap={2} flex={1}>
      <Controller
        control={control}
        name={textName}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            error={!!(errors as any)?.[textName]}
            helperText={(errors as any)?.[textName]?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={textLabel}
            sx={{ flex: 5, minWidth: 200 }}
          />
        )}
      />
      <Controller
        control={control}
        name={rewardName}
        defaultValue={undefined}
        render={({ field }) => (
          <TextField
            {...field}
            error={!!(errors as any)?.[rewardName]}
            helperText={(errors as any)?.[rewardName]?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="number"
            label="Récompense"
            onChange={(event) => field.onChange(Number(event.target.value))}
            sx={{ width: 200 }}
          />
        )}
      />
    </Stack>
  );
};

export default QuestionReward;
