import axios from "axios";
import { S3FoldersType } from "../../../backend/shared/types/S3/s3.type";

export const getPutSignedURL = async (
  fileName: string,
  folder: S3FoldersType
) =>
  axios
    .get<string>(`/s3/put-signed-url?filename=${fileName}&folder=${folder}`)
    .then((res) => res.data);
