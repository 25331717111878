import { FC } from "react";
import { TextField } from "@mui/material";
import { UseFormRegister } from "react-hook-form";
import { CryptorFormData } from "../utils/cryptor.schema";

interface CryptorLinkWithThePlaceFieldProps {
  register: UseFormRegister<CryptorFormData>;
}

const CryptorLinkWithThePlaceField: FC<CryptorLinkWithThePlaceFieldProps> = ({
  register,
}) => {
  return (
    <TextField
      {...register("linkWithThePlace")}
      defaultValue={null}
      margin="normal"
      fullWidth
      InputLabelProps={{ shrink: true }}
      type="text"
      label="Lien avec le lieu"
      name="linkWithThePlace"
    />
  );
};

export default CryptorLinkWithThePlaceField;
