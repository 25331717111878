import { jwtDecode } from "jwt-decode";
import { AuthBindings } from "@refinedev/core";
import axios from "axios";
import { postLogin } from "../api/auth.api";

const authProvider: AuthBindings = {
  login: async ({ email, password }) => {
    const { accessToken } = await postLogin({ email, password });
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    if (accessToken) {
      const user = jwtDecode(accessToken) as { role: string };
      if (user.role === "ADMIN") {
        localStorage.setItem("accessToken", accessToken);
        return { success: true, redirectTo: "/faq" };
      }
    }

    return {
      success: false,
      error: { message: "Login Error", name: "Invalid email or password" },
    };
  },
  check: async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      return { authenticated: true };
    }
    return { authenticated: false };
  },
  logout: async () => {
    localStorage.removeItem("accessToken");
    return { success: true, redirectTo: "/login" };
  },
  onError: async () => ({}),
};

export default authProvider;
