import React, { ChangeEvent, useMemo } from "react";
import { useDataGrid, List } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps } from "@refinedev/core";
import { IconButton, TextField } from "@mui/material";
import { useUpdateTranslation } from "../../api/translation.api";
import SaveIcon from "@mui/icons-material/Save";

export const TranslationList: React.FC<IResourceComponentsProps> = () => {
  const { dataGridProps, setFilters, filters } = useDataGrid({
    pagination: { mode: "client" },
    sorters: { mode: "off" },
    filters: { mode: "server" },
  });

  const { mutateAsync: updateTranslation } = useUpdateTranslation();

  const columns = useMemo<GridColDef[]>(
    () => [
      { field: "id", headerName: "Id", type: "number", minWidth: 50 },
      { field: "languageCode", headerName: "Langue", minWidth: 50 },
      {
        field: "value",
        headerName: "Valeur",
        minWidth: 200,
        flex: 1,
        editable: true,
      },
      {
        field: "action",
        headerName: "Action",
        minWidth: 200,
        sortable: false,

        renderCell: (record) => {
          return (
            <IconButton
              onClick={() => {
                updateTranslation({
                  id: record.id as number,
                  value: record.row.value,
                });
              }}
            >
              <SaveIcon />
            </IconButton>
          );
        },
      },
    ],
    [updateTranslation]
  );

  const handleFilter = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFilters([
      { field: "value", value: e.target.value, operator: "contains" },
    ]);
  };

  return (
    <List title="Traduction">
      <TextField
        value={filters[0]?.value || ""}
        onChange={handleFilter}
        placeholder="Rechercher"
        sx={{ width: 200, mb: 1 }}
      />
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        disableColumnFilter
        filterMode="server"
      />
    </List>
  );
};
