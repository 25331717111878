import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useDeleteHint,
  useGetHints,
  usePatchHint,
  usePostHint,
} from "../../api/hints.api";
import { Edit } from "@refinedev/mui";
import { Box, Button, Divider, IconButton, TextField } from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { getTranslation } from "../../utils/translation";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBack from "@mui/icons-material/ArrowBack";
import UploadField from "../../components/forms/UploadField";
import { Image } from "shared";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

export const HintsSchema = z.object({
  hints: z.array(
    z.object({
      id: z.string().nullable(),
      index: z.number().min(1),
      rewardIfNotUsed: z.number().min(0),
      descriptionTranslation: z.object({ value: z.string().min(1).optional() }),
      cryptorId: z.number().min(1),
      image: z.object({ url: z.string().min(1).nullable() }).nullable(),
    })
  ),
});

export type HintsFormData = z.infer<typeof HintsSchema>;

const HintsEdit = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const cryptorId = searchParams.get("cryptorId");

  const { data, isLoading } = useGetHints(Number(cryptorId));
  const { mutateAsync: postHint } = usePostHint();
  const { mutateAsync: patchHint } = usePatchHint();
  const { mutateAsync: deleteHint } = useDeleteHint();

  const formatedData = useMemo(
    () =>
      data.map((hint) => ({
        id: hint.id as string | null,
        index: Number(hint.index),
        rewardIfNotUsed: Number(hint.rewardIfNotUsed),
        descriptionTranslation: {
          value: getTranslation(hint.descriptionTranslation)?.value || "",
        },
        cryptorId: Number(cryptorId),
        image: hint.image as Image | null,
      })),
    [data, cryptorId]
  );

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, errors },
    setError,
  } = useForm<HintsFormData>({
    resolver: zodResolver(HintsSchema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  useEffect(() => {
    reset({ hints: formatedData });
  }, [reset, formatedData]);

  const {
    fields: hints,
    append: appendHint,
    remove: removeHint,
  } = useFieldArray({
    control,
    name: "hints",
    keyName: "key",
  });

  return (
    <Edit
      goBack={
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
      }
      headerButtons={() => null}
      isLoading={isLoading}
      saveButtonProps={{
        onClick: handleSubmit((data) => {
          for (const hint of data.hints) {
            const { id, image, ...hintWithoutId } = hint;
            const imageFormated =
              image && image.url ? { url: image.url } : null;

            if (id) {
              patchHint({
                id,
                data: { ...hintWithoutId, image: imageFormated },
              });
            } else {
              postHint({ ...hintWithoutId, image: imageFormated });
            }
          }
        }),
        disabled: !isValid,
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        {hints.map((hint, index) => (
          <Box key={hint.id}>
            <Box display="flex" flexDirection="row" gap={3}>
              <Box marginY={2}>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    const { id } = hint;
                    if (id) {
                      deleteHint(id);
                    } else {
                      removeHint(index);
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box display="flex" flexDirection="column" flex={1}>
                <Controller
                  key={hint.id}
                  name={`hints.${index}.id`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        key={hint.id}
                        {...field}
                        margin="normal"
                        label="Id"
                        disabled
                      />
                    );
                  }}
                />
                <Controller
                  key={hint.id}
                  name={`hints.${index}.descriptionTranslation.value`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        key={hint.id}
                        {...field}
                        margin="normal"
                        label="Description"
                        multiline
                        minRows={8}
                      />
                    );
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <Controller
                  key={hint.id}
                  name={`hints.${index}.index`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        key={hint.id}
                        {...field}
                        onChange={(event) =>
                          field.onChange(Number(event.target.value))
                        }
                        type="number"
                        margin="normal"
                        label="Index"
                      />
                    );
                  }}
                />
                <Controller
                  key={hint.id}
                  name={`hints.${index}.rewardIfNotUsed`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        key={hint.id}
                        {...field}
                        onChange={(event) =>
                          field.onChange(Number(event.target.value))
                        }
                        type="number"
                        margin="normal"
                        label="Récompense si pas découvert"
                      />
                    );
                  }}
                />
                <UploadField
                  name={`hints.${index}.image.url`}
                  uploadButtonLabel="Upload image"
                  s3FoldersType="hints"
                  control={control as any}
                  setError={setError as any}
                  errorMessage={(errors as any)?.hints?.[index]?.image?.url}
                />
              </Box>
            </Box>
            <Divider sx={{ marginTop: 3 }} />
          </Box>
        ))}
        <Divider sx={{ marginTop: 3 }}>
          <Button
            onClick={() =>
              appendHint({
                id: null,
                index: hints.length + 1,
                rewardIfNotUsed: 0,
                descriptionTranslation: {
                  value: "",
                },
                cryptorId: Number(cryptorId),
                image: null,
              })
            }
          >
            Ajouter un indice
          </Button>
        </Divider>
      </Box>
    </Edit>
  );
};

export default HintsEdit;
