import { Edit } from "@refinedev/mui";
import { Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps } from "@refinedev/core";
import FaqQuestions, { FaqQuestion } from "./FaqQuestions";
import { Controller, FieldValues } from "react-hook-form";
import { useEffect } from "react";
import { Faq } from "shared";
import { getTranslation } from "../../utils/translation";

export const FaqEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    control,
    refineCore: { formLoading, onFinish, queryResult },
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    setValue,
  } = useForm();

  const onFinishHandler = (data: FieldValues) => {
    onFinish({
      nameTranslation: { value: data.name },
      faqQuestions: data.faqQuestions.map((faqQuestion: any) => ({
        questionTranslation: { value: faqQuestion.question },
        answerTranslation: { value: faqQuestion.answer },
      })),
    });
  };

  const data = queryResult?.data?.data as Faq;

  useEffect(() => {
    if (!data) return;
    setValue("name", getTranslation(data.nameTranslation)?.value);
    setValue(
      "faqQuestions",
      data.faqQuestions.map((faqQuestion: FaqQuestion) => ({
        id: faqQuestion.id,
        question: getTranslation(faqQuestion.questionTranslation)?.value,
        answer: getTranslation(faqQuestion.answerTranslation)?.value,
      }))
    );
  }, [data, setValue]);

  register("faqQuestions");

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={{
        onClick: handleSubmit(onFinishHandler),
        disabled: !isDirty || !isValid,
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("id", {
            required: "Ce champ est requis",
            valueAsNumber: true,
          })}
          error={!!(errors as any)?.id}
          helperText={(errors as any)?.id?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          label="Id"
          name="id"
          disabled
        />
        <TextField
          {...register("name", {
            required: "Ce champ est requis",
          })}
          error={!!(errors as any)?.name}
          helperText={(errors as any)?.name?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label="Nom"
          name="name"
        />
        <Controller
          control={control}
          name="faqQuestions"
          defaultValue={[]}
          rules={{
            required: "Ce champ est requis",
            validate: (value: FaqQuestion[]) =>
              value.every((el) => el.answer && el.question),
          }}
          render={({ field }) => (
            <FaqQuestions
              faqQuestions={field.value}
              setValue={(value) => field.onChange(value)}
            />
          )}
        />
      </Box>
    </Edit>
  );
};
