import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useNotification } from "@refinedev/core";

export const updateTranslation = async (data: {
  id: number;
  value: string;
}) => {
  return axios
    .patch(`/translation/${data.id}`, { value: data.value })
    .then((res) => res.data);
};

export const useUpdateTranslation = () => {
  const { open } = useNotification();

  return useMutation({
    mutationFn: updateTranslation,
    onSuccess: () => {
      open?.({
        type: "success",
        message: "Modification réussie",
        description: "La modification a été effectuée avec succès",
      });
    },
    onError: () => {
      open?.({
        type: "error",
        message: "Echec de la modification",
        description: "Cette action a échoué. Veuillez réessayer",
      });
    },
  });
};
