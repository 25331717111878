import { Create } from "@refinedev/mui";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps } from "@refinedev/core";
import { Controller } from "react-hook-form";
import UploadButton from "../../components/forms/UploadButton";
import { upload } from "../../utils/upload";

import { Cryptor } from "../../../../backend/shared/types/cryptors/cryptors.type";
import { useMany, HttpError } from "@refinedev/core";
import { CreateAdvertisement } from "../../../../backend/shared/types/advertisement/advertisement.type";

interface AdvertisementCreateEditProps extends IResourceComponentsProps {
  isEditing?: boolean;
}

export const AdvertisementCreateEdit: React.FC<
  AdvertisementCreateEditProps
> = () => {
  const {
    refineCore: { formLoading, onFinish },
    register,
    formState: { errors, isValid, isDirty },
    control,
    handleSubmit,
    setError,
  } = useForm<CreateAdvertisement, HttpError, CreateAdvertisement>();

  const { data: cryptors } = useMany<Cryptor>({
    resource: "cryptors",
    ids: [],
  });

  const cryptorsData = cryptors?.data || [];

  const onFinishHandler = (data: CreateAdvertisement) => {
    onFinish({
      url: data.url,
      image: { url: data.image.url },
      cryptors: data.cryptors?.map((el) => ({ id: el.id })) || [],
      cryptorsDiscussion:
        data.cryptorsDiscussion?.map((el) => ({ id: el.id })) || [],
    });
  };

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        onClick: handleSubmit(onFinishHandler),
        disabled: !isDirty || !isValid,
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("url", {
            required: "Ce champ est requis",
          })}
          defaultValue={null}
          error={!!(errors as any)?.url}
          helperText={(errors as any)?.url?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Lien externe"
          name="url"
        />
        <Controller
          control={control}
          name="image.url"
          rules={{ required: "Ce champ est requis" }}
          render={({ field }) => (
            <UploadButton
              id="image"
              inputSrc={field.value}
              uploadButtonLabel="Upload Image"
              onError={() =>
                setError("image.url", {
                  message: "L'upload a échoué. Veuillez réessayer",
                })
              }
              onChange={async (event) => {
                const url = await upload(event, "advertisements");
                field.onChange(url);
              }}
              errorMessage={(errors as any)?.image?.url?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="cryptors"
          render={({ field }) => (
            <FormControl margin="normal">
              <InputLabel id="Cryptors">Cryptors</InputLabel>
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(
                    (e.target.value as []).map((el) => ({ id: el }))
                  );
                }}
                value={(field.value || []).map((el) => el.id)}
                labelId="cryptors"
                label="Cryptors"
                multiple
              >
                {cryptorsData.map((cryptor) => (
                  <MenuItem value={cryptor.id} key={cryptor.id}>
                    {cryptor.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="cryptorsDiscussion"
          render={({ field }) => (
            <FormControl margin="normal">
              <InputLabel id="CryptorsDiscussion">
                Cryptors (Discussion)
              </InputLabel>
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(
                    (e.target.value as []).map((el) => ({ id: el }))
                  );
                }}
                value={(field.value || []).map((el) => el.id)}
                labelId="cryptorsDiscussion"
                label="Cryptors (Discussion)"
                multiple
              >
                {cryptorsData.map((cryptor) => (
                  <MenuItem value={cryptor.id} key={cryptor.id}>
                    {cryptor.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Box>
    </Create>
  );
};
