import { Link } from "react-router-dom";
import { ReactComponent as CryptorsLogo } from "../../assets/svg/CryptorsLogo.svg";
import { Box, useTheme } from "@mui/material";

type TitleProps = {
  collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const { palette } = useTheme();
  return (
    <Link to="/">
      <Box
        sx={{
          height: "72px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "text.primary",
          padding: "10px",
        }}
      >
        <CryptorsLogo
          height="100%"
          width="100%"
          color={palette.text.primary}
          stroke={palette.text.primary}
          fill={palette.text.primary}
        />
      </Box>
    </Link>
  );
};
