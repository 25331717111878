import UploadButton from "./UploadButton";
import {
  Control,
  Controller,
  FieldValues,
  UseFormSetError,
} from "react-hook-form";
import { upload } from "../../utils/upload";
import { S3FoldersType } from "../../../../backend/shared/types/S3/s3.type";
import { FC } from "react";

interface UploadFieldProps {
  control: Control<FieldValues, {}>;
  setError: UseFormSetError<FieldValues>;
  errorMessage: string;
  s3FoldersType: S3FoldersType;
  name: string;
  uploadButtonLabel: string;
}

const UploadField: FC<UploadFieldProps> = ({
  control,
  setError,
  errorMessage,
  s3FoldersType,
  name,
  uploadButtonLabel,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      rules={{ required: "Ce champ est requis" }}
      render={({ field }) => (
        <UploadButton
          id={name}
          inputSrc={field.value}
          uploadButtonLabel={uploadButtonLabel}
          onError={() =>
            setError(name, { message: "L'upload a échoué. Veuillez réessayer" })
          }
          errorMessage={errorMessage}
          onChange={async (event) => {
            const url = await upload(event, s3FoldersType);
            field.onChange(url);
          }}
          onDelete={() => field.onChange(null)}
        />
      )}
    />
  );
};

export default UploadField;
