import { S3FoldersType } from "../../../backend/shared/types/S3/s3.type";
import { getPutSignedURL } from "../api/s3.api";

export const upload = async (
  event: React.ChangeEvent<HTMLInputElement>,
  folder: S3FoldersType
) => {
  const target = event.target;
  const file: File = (target.files as FileList)[0];
  const { name } = file;

  const putPresignedUrl = await getPutSignedURL(name, folder);

  const blob = new Blob([file], { type: file.type });
  await fetch(putPresignedUrl, { method: "PUT", body: blob });

  return putPresignedUrl.split("?")[0];
};
