import { Create } from "@refinedev/mui";
import { Box } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps } from "@refinedev/core";
import { Controller, FieldValues } from "react-hook-form";
import UploadButton from "../../components/forms/UploadButton";
import { upload } from "../../utils/upload";

export const AvatarCreate: React.FC<IResourceComponentsProps> = () => {
  const {
    refineCore: { formLoading, onFinish },
    control,
    formState: { errors, isValid, isDirty },
    setError,
    handleSubmit,
  } = useForm();

  const onFinishHandler = (data: FieldValues) => {
    onFinish({
      image: { url: data.image.url },
    });
  };

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        onClick: handleSubmit(onFinishHandler),
        disabled: !isDirty || !isValid,
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Controller
          control={control}
          name="image.url"
          defaultValue={null}
          rules={{ required: "Ce champ est requis" }}
          render={({ field }) => (
            <UploadButton
              inputSrc={field.value}
              onError={() =>
                setError("image.url", {
                  message: "L'upload a échoué. Veuillez réessayer",
                })
              }
              onChange={async (event) => {
                const url = await upload(event, "avatars");
                field.onChange(url);
              }}
              errorMessage={(errors as any)?.image?.url?.message}
            />
          )}
        />
      </Box>
    </Create>
  );
};
