import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useAutocomplete } from "@refinedev/mui";
import { useGo } from "@refinedev/core";
import ClearIcon from "@mui/icons-material/Clear";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ChatMessageShow } from "../../chatMessage/ChatMessageShow";
import UploadField from "../../../components/forms/UploadField";

interface CryptorArtefactFieldProps {
  cryptorId?: number;
  artefactName: string;
  title?: string;
}

const CryptorArtefactField: FC<CryptorArtefactFieldProps> = ({
  cryptorId,
  artefactName,
  title,
}) => {
  const go = useGo();
  const {
    control,
    register,
    setError,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const { autocompleteProps } = useAutocomplete({
    resource: `chat/cryptors/${cryptorId}/chat-message`,
    defaultValue: undefined,
    queryOptions: { enabled: cryptorId !== undefined },
  });

  watch(artefactName);
  const isActive = watch(`${artefactName}.isActive`);

  return (
    <Box>
      <Box display="flex" flexDirection="row" gap={3}>
        <h2>{title}</h2>
        <Controller
          control={control}
          name={`${artefactName}.isActive`}
          render={({ field }) => {
            if (field.value) {
              return (
                <IconButton
                  onClick={() => {
                    setValue(`${artefactName}`, { isActive: false });
                  }}
                  sx={{ alignSelf: "center" }}
                >
                  <ClearIcon />
                </IconButton>
              );
            }
            return (
              <Button
                onClick={() => {
                  setValue(`${artefactName}`, {
                    isActive: true,
                    id: undefined,
                  });
                }}
                sx={{ alignSelf: "center" }}
              >
                Ajouter
              </Button>
            );
          }}
        />
      </Box>

      {isActive && (
        <>
          <TextField
            {...register(`${artefactName}.titlePopUp`, {
              required: "Ce champ est requis",
            })}
            defaultValue={null}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Titre de la pop-up"
          />
          <TextField
            {...register(`${artefactName}.textPopUp`, {
              required: "Ce champ est requis",
            })}
            defaultValue={null}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label="Texte de la pop-up"
            multiline
            minRows={3}
          />

          <Box display="flex" flexDirection="row" gap={3}>
            <TextField
              {...register(`${artefactName}.reward`, {
                required: "Ce champ est requis",
                valueAsNumber: true,
              })}
              defaultValue={null}
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="number"
              label="Récompense"
            />

            {cryptorId && (
              <Controller
                control={control}
                name={`${artefactName}.chatMessageId`}
                defaultValue=""
                render={({ field }) => {
                  return (
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={2}
                      minWidth={200}
                      maxWidth={300}
                    >
                      <FormControl margin="normal" style={{ flex: 1 }}>
                        <InputLabel id="children-select-label">
                          Chat message
                        </InputLabel>
                        <Select
                          {...field}
                          defaultValue={field.value}
                          labelId="children-select-label"
                          label="Chat message"
                        >
                          {[
                            { id: null, text: "Aucun" },
                            ...autocompleteProps.options,
                          ].map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              <CustomWidthTooltip
                                title={
                                  option.id ? (
                                    <ChatMessageShow idProps={option.id} />
                                  ) : null
                                }
                                placement="top-end"
                              >
                                <Stack flex={1}>
                                  {option.id} {option.text}
                                </Stack>
                              </CustomWidthTooltip>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <IconButton
                        onClick={() => {
                          go({
                            to: `/cryptors/${cryptorId}/chat-message/${field.value}`,
                          });
                        }}
                        disabled={!field.value}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </Stack>
                  );
                }}
              />
            )}
          </Box>

          <UploadField
            name={`${artefactName}.image.url`}
            uploadButtonLabel="Upload image"
            s3FoldersType={"cryptors"}
            control={control}
            setError={setError}
            errorMessage={(errors as any)[artefactName]?.image?.url?.message}
          />
        </>
      )}
    </Box>
  );
};

export default CryptorArtefactField;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 700,
  },
});
