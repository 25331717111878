import { Box, Select, MenuItem } from "@mui/material";
import i18n from "../../i18n";

const SelectLanguage = () => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Select
        value={i18n.language}
        onChange={(event: any) => {
          i18n.changeLanguage(event.target.value);
        }}
      >
        {(i18n.options.supportedLngs || [])
          .filter((el) => el !== "cimode")
          .map((lng) => (
            <MenuItem key={lng} value={lng}>
              {lng}
            </MenuItem>
          ))}
      </Select>
    </Box>
  );
};

export default SelectLanguage;
