import { TextField } from "@mui/material";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { CryptorFormData } from "../utils/cryptor.schema";

interface CryptorQuoteFieldProps {
  register: UseFormRegister<CryptorFormData>;
}

const CryptorQuoteField: FC<CryptorQuoteFieldProps> = ({ register }) => {
  return (
    <TextField
      {...register("quote")}
      defaultValue={null}
      margin="normal"
      fullWidth
      InputLabelProps={{ shrink: true }}
      type="text"
      label="Citation"
      name="quote"
    />
  );
};

export default CryptorQuoteField;
