import { TextField } from "@mui/material";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { CryptorFormData } from "../utils/cryptor.schema";

interface CryptorPrimaryColorFieldProps {
  register: UseFormRegister<CryptorFormData>;
}

const CryptorPrimaryColorField: FC<CryptorPrimaryColorFieldProps> = ({
  register,
}) => {
  return (
    <TextField
      {...register("primaryColor", {
        required: "Ce champ est requis",
      })}
      defaultValue={null}
      margin="normal"
      fullWidth
      InputLabelProps={{ shrink: true }}
      type="text"
      label="Couleur primaire"
      name="primaryColor"
    />
  );
};

export default CryptorPrimaryColorField;
