import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FC, useCallback, useMemo, useState } from "react";
import { Faq } from "../../../../backend/shared/types/faq/faq.type";
import { DeleteButton } from "@refinedev/mui";
import { useTranslate } from "@refinedev/core";

export type FaqQuestion = Omit<Faq["faqQuestions"][0], "faqId">;

interface FaqQuestionsProps {
  setValue: (value: any) => void;
  faqQuestions: FaqQuestion[];
}

const FaqQuestions: FC<FaqQuestionsProps> = ({ setValue, faqQuestions }) => {
  const t = useTranslate();

  const [faqQuestionIdToBeDeleted, setFaqQuestionIdToBeDeleted] =
    useState<number>();

  const handleDialogOpen = useCallback(
    (faqQuestionId: number) => {
      setFaqQuestionIdToBeDeleted(faqQuestionId);
    },
    [setFaqQuestionIdToBeDeleted]
  );

  const handleDialogClose = useCallback(() => {
    setFaqQuestionIdToBeDeleted(undefined);
  }, [setFaqQuestionIdToBeDeleted]);

  const handleDelete = useCallback(
    (faqQuestionId: number) => {
      setValue(
        faqQuestions.filter((faqQuestion) => faqQuestion.id !== faqQuestionId)
      );
      handleDialogClose();
    },
    [handleDialogClose, faqQuestions, setValue]
  );

  const handleCellEdit = useCallback(
    (newRow: FaqQuestion) => {
      setValue(
        faqQuestions.map((faqQuestion) =>
          faqQuestion.id === newRow.id ? { ...newRow } : faqQuestion
        )
      );
    },
    [setValue, faqQuestions]
  );

  const addNewRow = useCallback(() => {
    const newRow = {
      id: Math.random(),
      question: "",
      answer: "",
    };
    setValue([...faqQuestions, newRow]);
  }, [setValue, faqQuestions]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "question",
        flex: 1,
        headerName: "Question",
        minWidth: 200,
        editable: true,
      },
      {
        field: "answer",
        flex: 1,
        headerName: "Réponse",
        editable: true,
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        width: 150,
        renderCell: (params) => {
          return (
            <DeleteButton
              hideText
              onClick={() => handleDialogOpen(params.row.id)}
            />
          );
        },
      },
    ],
    [handleDialogOpen]
  );

  return (
    <>
      <DataGrid
        rows={faqQuestions}
        columns={columns}
        autoHeight
        disableRowSelectionOnClick
        processRowUpdate={(value) => handleCellEdit(value)}
      />
      <Button variant="contained" color="primary" onClick={addNewRow}>
        {t("faq.addQuestion")}
      </Button>
      <Dialog
        open={!!faqQuestionIdToBeDeleted}
        onClose={() => setFaqQuestionIdToBeDeleted(undefined)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("buttons.confirm")}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>{t("buttons.cancel")}</Button>
          <Button
            onClick={() =>
              faqQuestionIdToBeDeleted && handleDelete(faqQuestionIdToBeDeleted)
            }
            autoFocus
            color="error"
          >
            {t("buttons.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FaqQuestions;
