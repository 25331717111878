import { Create } from "@refinedev/mui";
import { Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps } from "@refinedev/core";
import { FieldValues } from "react-hook-form";

export const CitiesCreate: React.FC<IResourceComponentsProps> = () => {
  const {
    refineCore: { formLoading, onFinish },
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
  } = useForm();

  const onFinishHandler = (data: FieldValues) => {
    onFinish({
      name: data.name,
      location: {
        latitude: data.location.latitude,
        longitude: data.location.longitude,
      },
    });
  };

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        onClick: handleSubmit(onFinishHandler),
        disabled: !isDirty || !isValid,
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("name", {
            required: "Ce champ est requis",
          })}
          defaultValue={null}
          error={!!(errors as any)?.name}
          helperText={(errors as any)?.name?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label="Nom"
          name="name"
        />
        <TextField
          {...register("location.latitude", {
            required: "Ce champ est requis",
            valueAsNumber: true,
          })}
          defaultValue={null}
          error={!!(errors as any)?.location?.latitude}
          helperText={(errors as any)?.location?.latitude?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          label="Latitude"
          name="location.latitude"
        />
        <TextField
          {...register("location.longitude", {
            required: "Ce champ est requis",
            valueAsNumber: true,
          })}
          defaultValue={null}
          error={!!(errors as any)?.location?.longitude}
          helperText={(errors as any)?.location?.longitude?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          label="Longitude"
          name="location.longitude"
        />
      </Box>
    </Create>
  );
};
