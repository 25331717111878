import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetHints } from "../../api/hints.api";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Show, TextFieldComponent as TextField } from "@refinedev/mui";
import i18n from "../../i18n";
import { getTranslation } from "../../utils/translation";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import { Fragment } from "react";

const HintsShow = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const cryptorId = searchParams.get("cryptorId");

  const { data: hints, isLoading } = useGetHints(Number(cryptorId));

  return (
    <Show
      isLoading={isLoading}
      goBack={
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
      }
      headerButtons={() => (
        <Stack gap={1} display="flex" alignItems="center">
          <LoadingButton
            onClick={() => {
              navigate(`/hints/edit?cryptorId=${cryptorId}`);
              navigate(0);
            }}
          >
            <Box display="flex" gap={0.5}>
              <EditIcon />
              Modifier
            </Box>
          </LoadingButton>
        </Stack>
      )}
    >
      <Stack gap={1}>
        {hints.map((hint) => (
          <Fragment key={hint.id}>
            <Typography variant="body1" fontWeight="bold">
              Id
            </Typography>
            <TextField value={hint.id} />
            <Typography variant="body1" fontWeight="bold">
              Index
            </Typography>
            <TextField value={hint.index} />
            <Typography variant="body1" fontWeight="bold">
              Description
            </Typography>
            <TextField
              value={
                getTranslation(hint.descriptionTranslation, i18n.language)
                  ?.value
              }
            />
            <Typography variant="body1" fontWeight="bold">
              Récompense si pas découvert
            </Typography>
            <TextField value={hint.rewardIfNotUsed} />
            {hint.image?.url && (
              <Box>
                <Typography variant="body1" fontWeight="bold">
                  Image
                </Typography>
                <img src={hint.image?.url} alt="Indice" width="100" />
              </Box>
            )}

            <Divider sx={{ marginTop: 3 }} />
          </Fragment>
        ))}
      </Stack>
    </Show>
  );
};

export default HintsShow;
