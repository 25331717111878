import { FC } from "react";
import { TextField } from "@mui/material";
import { UseFormRegister } from "react-hook-form";
import { CryptorFormData } from "../utils/cryptor.schema";

interface CryptorTitleFieldProps {
  register: UseFormRegister<CryptorFormData>;
}

const CryptorTitleField: FC<CryptorTitleFieldProps> = ({ register }) => {
  return (
    <TextField
      {...register("title")}
      defaultValue={null}
      margin="normal"
      fullWidth
      InputLabelProps={{ shrink: true }}
      type="text"
      label="Titre"
      name="title"
    />
  );
};

export default CryptorTitleField;
