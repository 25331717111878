import React, { useMemo } from "react";
import {
  useDataGrid,
  List,
  ShowButton,
  DeleteButton,
  EditButton,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps } from "@refinedev/core";
import { getTranslation } from "../../utils/translation";

export const FaqList: React.FC<IResourceComponentsProps> = () => {
  const {
    dataGridProps: { rows, ...rest },
  } = useDataGrid({
    pagination: { mode: "client" },
    sorters: { mode: "off" },
    filters: { mode: "off" },
  });

  const formatedData = rows.map((faq) => ({
    id: faq.id,
    name: getTranslation(faq.nameTranslation)?.value,
  }));

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: "Id",
        type: "number",
        minWidth: 50,
      },
      {
        field: "name",
        flex: 1,
        headerName: "Nom",
        minWidth: 200,
      },
      {
        field: "action",
        headerName: "Action",
        minWidth: 200,
        sortable: false,
        renderCell: (record) => {
          return (
            <>
              <ShowButton hideText recordItemId={record.id} />
              <EditButton hideText recordItemId={record.id} />
              <DeleteButton hideText recordItemId={record.id} />
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <List title="FAQ">
      <DataGrid {...rest} rows={formatedData} columns={columns} autoHeight />
    </List>
  );
};
