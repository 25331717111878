import {
  InputLabel,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  styled,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useGo } from "@refinedev/core";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ChatMessageShow } from "../ChatMessageShow";
import { getTranslation } from "../../../utils/translation";

interface ChatMessageChildrenSelectorProps {
  control: Control;
  name: string;
  autocompleteProps: any;
  label?: string;
}

const ChatMessageChildrenSelector: FC<ChatMessageChildrenSelectorProps> = ({
  control,
  name,
  autocompleteProps,
  label = "Enfant",
}) => {
  const { cryptorId } = useParams();
  const go = useGo();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field }) => {
        return (
          <Stack direction="row" alignItems="center" gap={2} minWidth={200}>
            <FormControl margin="normal" style={{ flex: 1 }}>
              <InputLabel id="children-select-label">{label}</InputLabel>
              <Select
                {...field}
                defaultValue={field.value}
                labelId="children-select-label"
                label="Enfant"
              >
                {[
                  { id: null, text: "Aucun" },
                  ...autocompleteProps.options,
                ].map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    <CustomWidthTooltip
                      title={
                        option.id ? (
                          <ChatMessageShow idProps={option.id} />
                        ) : null
                      }
                      placement="top-end"
                    >
                      <Stack flex={1}>
                        {option.id}{" "}
                        {getTranslation(option.textTranslation)?.value}
                      </Stack>
                    </CustomWidthTooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton
              onClick={() => {
                go({
                  to: `/cryptors/${cryptorId}/chat-message/${field.value}`,
                });
              }}
              disabled={!field.value}
            >
              <OpenInNewIcon />
            </IconButton>
          </Stack>
        );
      }}
    />
  );
};

export default ChatMessageChildrenSelector;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 700,
  },
});
