import axios from "axios";
import { Hint, CreateHint, UpdateHint } from "shared";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const getHints = async (cryptorId: number) =>
  axios.get<Hint[]>(`/hints?cryptorId=${cryptorId}`).then((res) => res.data);

export const useGetHints = (cryptorId: number) =>
  useQuery({
    initialData: [],
    queryKey: ["hints", cryptorId],
    queryFn: () => getHints(cryptorId),
  });

const postHint = async (data: CreateHint) => {
  return axios.post(`/hints`, data).then((res) => res.data);
};

export const usePostHint = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postHint,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["hints"] });
    },
  });
};

const patchHint = async ({ id, data }: { id: string; data: UpdateHint }) => {
  return axios.patch(`/hints/${id}`, data).then((res) => res.data);
};

export const usePatchHint = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchHint,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["hints"] });
    },
  });
};

const deleteHint = async (id: string) => {
  return axios.delete(`/hints/${id}`).then((res) => res.data);
};

export const useDeleteHint = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteHint,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["hints"] });
    },
  });
};
