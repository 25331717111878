import React, { useMemo } from "react";
import {
  useDataGrid,
  EditButton,
  ShowButton,
  DeleteButton,
  List,
  DateField,
  ListButton,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps } from "@refinedev/core";
import { useNavigate } from "react-router-dom";

export const CryptorList: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();

  const { dataGridProps } = useDataGrid({
    pagination: { mode: "client" },
    sorters: { mode: "off" },
    filters: { mode: "off" },
  });

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: "Id",
        type: "number",
        minWidth: 50,
      },
      {
        field: "name",
        flex: 1,
        headerName: "Nom",
        minWidth: 200,
      },
      {
        field: "image.url",
        headerName: "Image",
        minWidth: 50,
        valueGetter: (params) => params.row?.image?.url,
        renderCell: function render({ value }) {
          return <img src={value} alt="Cryptor" width="50" />;
        },
      },
      {
        field: "reward",
        headerName: "Récompense",
        type: "number",
        minWidth: 50,
      },
      {
        field: "createdAt",
        headerName: "Date de création",
        minWidth: 120,
        renderCell: function render({ value }) {
          return <DateField value={value} />;
        },
      },
      {
        field: "chat",
        headerName: "Chat",
        sortable: false,
        width: 50,
        renderCell: function render({ row }) {
          return (
            <>
              <ListButton
                hideText
                onClick={() => navigate(`/cryptors/${row.id}/chat-message`)}
              />
            </>
          );
        },
      },
      {
        field: "archived",
        headerName: "Archivé",
        type: "boolean",
        width: 70,
      },
      {
        field: "draft",
        headerName: "Publié",
        type: "boolean",
        width: 70,
        valueGetter: (params) => !params.row.draft,
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
              <ShowButton hideText recordItemId={row.id} />
              <DeleteButton
                hideText
                recordItemId={row.id}
                confirmTitle="Etes-vous sûr de vouloir supprimer ce cryptor ? Cette action est définitive et entraînera la suppression de tous les points accumulés par les utilisateurs."
              />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 90,
      },
    ],
    [navigate]
  );

  return (
    <List>
      <DataGrid {...dataGridProps} columns={columns} autoHeight />
    </List>
  );
};
