import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FC, useMemo } from "react";
import { Cryptor } from "shared";

interface CryptorAddressesProps {
  data: Cryptor["addresses"];
}

const CryptorAddresses: FC<CryptorAddressesProps> = ({ data }) => {
  const addresses = useMemo(() => data?.flatMap((el) => el.address), [data]);

  const columns = useMemo<
    GridColDef<Cryptor["addresses"][number]["address"]>[]
  >(
    () => [
      {
        field: "id",
        headerName: "ID",
        type: "number",
        width: 50,
      },
      {
        field: "locationAddress",
        headerName: "Nom Adresse",
        minWidth: 400,
        flex: 1,
      },
      {
        field: "city",
        headerName: "Ville",
        minWidth: 120,
        flex: 0.3,
        valueGetter: (params) => params.row?.city.name,
      },
      {
        field: "latitude",
        headerName: "Latitude",
        minWidth: 50,
        valueGetter: (params) => params.row?.location?.latitude,
      },
      {
        field: "longitude",
        headerName: "Longitude",
        minWidth: 50,
        valueGetter: (params) => params.row?.location?.longitude,
      },
      {
        field: "locationRadius",
        headerName: "Rayon de localisation (en mètres)",
        type: "number",
        minWidth: 120,
        valueGetter: (params) => params.row?.locationRadius,
      },
    ],
    []
  );

  return (
    <div style={{ padding: "4px" }}>
      <h2>Adresses</h2>
      <DataGrid rows={addresses ?? []} columns={columns} />
    </div>
  );
};

export default CryptorAddresses;
