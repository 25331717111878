import { useShow, IResourceComponentsProps } from "@refinedev/core";
import {
  Show,
  NumberField,
  TextFieldComponent as TextField,
} from "@refinedev/mui";
import { Typography, Stack, List, Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { getTranslation } from "../../utils/translation";
import { FaqQuestion } from "./FaqQuestions";
import SelectLanguage from "../../components/miscellaneous/SelectLanguage";
import i18n from "../../i18n";

export const FaqShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();

  const { data, isLoading } = queryResult;

  const record = data?.data;

  const dataFormated = {
    name: getTranslation(record?.nameTranslation, i18n.language)?.value,
    faqQuestions: record?.faqQuestions.map((faqQuestion: FaqQuestion) => ({
      id: faqQuestion.id,
      question: getTranslation(faqQuestion.questionTranslation, i18n.language)
        ?.value,
      answer: getTranslation(faqQuestion.answerTranslation, i18n.language)
        ?.value,
    })),
  };

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: "Id",
        type: "number",
        minWidth: 50,
      },
      {
        field: "question",
        flex: 1,
        headerName: "Question",
        minWidth: 200,
      },
      {
        field: "answer",
        flex: 1,
        headerName: "Réponse",
      },
    ],
    []
  );

  if (!data) return null;

  return (
    <Show
      isLoading={isLoading}
      headerButtons={({ defaultButtons }) => (
        <Box display="flex" alignItems="center" gap={1}>
          {defaultButtons}
          <SelectLanguage />
        </Box>
      )}
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Id
        </Typography>
        <NumberField value={record?.id ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Name
        </Typography>
        <TextField value={dataFormated?.name ?? ""} />
        <List title="FAQ">
          <DataGrid
            rows={dataFormated.faqQuestions}
            columns={columns}
            autoHeight
          />
        </List>
      </Stack>
    </Show>
  );
};
