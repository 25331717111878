import { FC } from "react";
import { TextField } from "@mui/material";
import { UseFormRegister } from "react-hook-form";
import { CryptorFormData } from "../utils/cryptor.schema";

interface CryptorPlaceOfBirthFieldProps {
  register: UseFormRegister<CryptorFormData>;
}

const CryptorPlaceOfBirthField: FC<CryptorPlaceOfBirthFieldProps> = ({
  register,
}) => {
  return (
    <TextField
      {...register("placeOfBirth")}
      defaultValue={null}
      margin="normal"
      fullWidth
      InputLabelProps={{ shrink: true }}
      type="text"
      label="Lieu de naissance"
      name="placeOfBirth"
    />
  );
};

export default CryptorPlaceOfBirthField;
