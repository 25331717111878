import { useShow, IResourceComponentsProps } from "@refinedev/core";
import {
  Show,
  NumberField,
  TextFieldComponent as TextField,
  DateField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

export const CitiesShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Id
        </Typography>
        <NumberField value={record?.id ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Nom
        </Typography>
        <TextField value={record?.name} />
        <Typography variant="body1" fontWeight="bold">
          Latitude
        </Typography>
        <NumberField value={record?.location?.latitude ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Longitude
        </Typography>
        <NumberField value={record?.location?.longitude ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Date de création
        </Typography>
        <DateField value={record?.createdAt} />
      </Stack>
    </Show>
  );
};
