import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { DateField } from "@mui/x-date-pickers";
import { CryptorFormData } from "../utils/cryptor.schema";

interface CryptorBirthDateFieldProps {
  control: Control<CryptorFormData, {}>;
}

const CryptorBirthDateField: FC<CryptorBirthDateFieldProps> = ({ control }) => {
  return (
    <Controller
      control={control}
      name="birthDate"
      render={({ field }) => (
        <DateField
          label="Date de naissance"
          value={field.value}
          onChange={field.onChange}
          margin="normal"
          minDate={new Date("0000-01-01")}
        />
      )}
    />
  );
};

export default CryptorBirthDateField;
