import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { City, Location } from "shared";
import { useGetCities } from "../../../api/cities.api";
import CloseIcon from "@mui/icons-material/Close";
import { CryptorFormData } from "../utils/cryptor.schema";

export type CryptorAddress = {
  address: {
    tempId?: number;
    id?: number;
    locationAddress: string;
    city: City;
    location: Location;
    locationRadius: number;
  };
};

const CryptorAddressesField = () => {
  const { data: cities } = useGetCities();

  const { watch, control, setValue } = useFormContext<CryptorFormData>();

  const addresses = watch("addresses");

  const handleAddAddress = () => {
    setValue("addresses", [
      ...addresses.map((el) => ({
        locationAddress: el.locationAddress,
        cityId: el.cityId,
        location: el.location,
        locationRadius: el.locationRadius,
      })),
      {
        locationAddress: "",
        cityId: 1,
        location: { latitude: 0, longitude: 0 },
        locationRadius: 0,
      },
    ]);
  };

  return (
    <div style={{ padding: "4px" }}>
      <h2>Adresses</h2>
      <Button variant="contained" color="primary" onClick={handleAddAddress}>
        Ajouter une nouvelle adresse
      </Button>
      {watch("addresses")?.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Adresse</th>
              <th>Ville</th>
              <th>Latitude</th>
              <th>Longitude</th>
              <th>Rayon de localisation (en mètres)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {addresses?.map((address, index: number) => (
              <tr key={index}>
                <td>
                  <TextField
                    value={address.locationAddress}
                    onChange={(event) => {
                      setValue(
                        `addresses.${index}.locationAddress`,
                        event.target.value
                      );
                    }}
                    style={{ width: "100%" }}
                  />
                </td>
                <td style={{ transform: "translateY(-4px)" }}>
                  <FormControl sx={{ marginTop: 1, width: "100%" }}>
                    <InputLabel htmlFor="outlined-adornment">Ville</InputLabel>
                    <Select
                      value={address.cityId}
                      onChange={(event) => {
                        setValue(
                          `addresses.${index}.cityId`,
                          Number(event.target.value)
                        );
                      }}
                      label="Ville"
                    >
                      {cities.map((city) => (
                        <MenuItem key={city.id} value={city.id}>
                          {city.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </td>
                <td>
                  <Controller
                    name={`addresses.${index}.location.latitude`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`addresses.${index}.location.longitude`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        style={{ width: "100%" }}
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`addresses.${index}.locationRadius`}
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} style={{ width: "100%" }} />
                    )}
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => {
                      setValue(
                        `addresses`,
                        addresses
                          .filter((el, i) => i !== index)
                          .map((el) => ({
                            locationAddress: el.locationAddress,
                            cityId: el.cityId,
                            location: el.location,
                            locationRadius: el.locationRadius,
                          }))
                      );
                    }}
                    sx={{ float: "right" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CryptorAddressesField;
