import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { Avatar } from "shared";

const getAvatars = async () =>
  axios.get<Avatar[]>("/avatars/owned").then((res) => res.data);

export const useGetAvatars = () =>
  useQuery({
    initialData: [],
    queryKey: ["avatars"],
    queryFn: getAvatars,
  });
