import { Create } from "@refinedev/mui";
import { Box, TextField, MenuItem } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps } from "@refinedev/core";
import { Controller, FieldValues } from "react-hook-form";
import { useGetAvatars } from "../../api/avatar.api";
import StyledSelect from "../../components/forms/StyledSelect";
import UserCryptorDraftAccessSelect from "./components/UserCryptorDraftAccessSelect";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const { data: avatars } = useGetAvatars();
  const {
    refineCore: { formLoading, onFinish },
    register,
    control,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    watch,
  } = useForm();

  const onFinishHandler = (data: FieldValues) => {
    onFinish({
      pseudo: data.pseudo,
      email: data.email,
      password: data.password,
      role: data.role,
      avatarId: data.avatarId,
      cryptorsDraftAccess: data.cryptorsDraftAccess?.map((el: any) => el.id),
    });
  };

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        onClick: handleSubmit(onFinishHandler),
        disabled: !isDirty || !isValid,
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("pseudo", {
            required: "Ce champ est requis",
          })}
          defaultValue={null}
          error={!!(errors as any)?.pseudo}
          helperText={(errors as any)?.pseudo?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label="Pseudo"
          name="pseudo"
        />
        <TextField
          {...register("email", {
            required: "Ce champ est requis",
          })}
          defaultValue={null}
          error={!!(errors as any)?.email}
          helperText={(errors as any)?.email?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="email"
          label="Email"
          name="email"
        />
        <TextField
          {...register("password", {
            required: "Ce champ est requis",
          })}
          defaultValue={null}
          error={!!(errors as any)?.email}
          helperText={(errors as any)?.email?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="password"
          label="Mot de passe"
          name="password"
        />
        <Controller
          control={control}
          name="role"
          defaultValue={null}
          rules={{ required: "Ce champ est requis" }}
          render={({ field }) => (
            <StyledSelect
              label="Role"
              selectProps={field}
              errorMessage={(errors as any)?.role?.message}
            >
              <MenuItem value="USER">User</MenuItem>
              <MenuItem value="ADMIN">Admin</MenuItem>
            </StyledSelect>
          )}
        />
        <Controller
          control={control}
          name="avatarId"
          defaultValue={null}
          rules={{ required: "Ce champ est requis" }}
          render={({ field }) => (
            <StyledSelect
              label="Avatar"
              selectProps={field}
              errorMessage={(errors as any)?.avatar?.message}
            >
              {avatars.map((avatar) => (
                <MenuItem key={avatar.id} value={avatar.id}>
                  <img alt="avatar" src={avatar.image.url} width={35} />
                </MenuItem>
              ))}
            </StyledSelect>
          )}
        />
        {watch("role") !== "ADMIN" && (
          <UserCryptorDraftAccessSelect control={control} />
        )}
      </Box>
    </Create>
  );
};
