import { Refine, Authenticated } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import {
  AuthPage,
  ErrorComponent,
  notificationProvider,
  RefineSnackbarProvider,
  ThemedLayoutV2,
} from "@refinedev/mui";
import { useTranslation } from "react-i18next";
import GlobalStyles from "@mui/material/GlobalStyles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import routerBindings, {
  UnsavedChangesNotifier,
  DocumentTitleHandler,
} from "@refinedev/react-router-v6";
import PersonIcon from "@mui/icons-material/Person";
import BookIcon from "@mui/icons-material/Book";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import HelpIcon from "@mui/icons-material/Help";
import FaceIcon from "@mui/icons-material/Face";
import TranslateIcon from "@mui/icons-material/Translate";

import { ColorModeContextProvider } from "./contexts/color-mode";
import dataProvider from "@refinedev/nestjsx-crud";
import axios from "axios";
import { FaqList } from "./pages/faq/FaqList";
import { FaqShow } from "./pages/faq/FaqShow";
import { FaqCreate } from "./pages/faq/FaqCreate";
import { FaqEdit } from "./pages/faq/FaqEdit";
import { Header } from "./components";
import { Title } from "./components/title/Title";
import { UserList } from "./pages/user/UserList";

import { UserShow } from "./pages/user/UserShow";
import { UserEdit } from "./pages/user/UserEdit";
import { UserCreate } from "./pages/user/UserCreate";
import { CryptorList } from "./pages/cryptor/CryptorList";
import { CryptorShow } from "./pages/cryptor/CryptorShow";
import { CryptorCreate } from "./pages/cryptor/CryptorCreate";
import { CryptorEdit } from "./pages/cryptor/CryptorEdit";
import { CitiesCreate } from "./pages/cities/CitiesCreate";
import { CitiesEdit } from "./pages/cities/CitiesEdit";
import { CitiesList } from "./pages/cities/CitiesList";
import { CitiesShow } from "./pages/cities/CitiesShow";
import { AvatarCreate } from "./pages/avatar/AvatarCreate";
import { AvatarEdit } from "./pages/avatar/AvatarEdit";
import { AvatarList } from "./pages/avatar/AvatarList";
import { AvatarShow } from "./pages/avatar/AvatarShow";
import authProvider from "./providers/authProvider";
import { ChatMessageCreate } from "./pages/chatMessage/ChatMessageCreate";
import { ChatMessageList } from "./pages/chatMessage/ChatMessageList";
import { ChatMessageShow } from "./pages/chatMessage/ChatMessageShow";
import { ChatMessageEdit } from "./pages/chatMessage/ChatMessageEdit";
import { LevelList } from "./pages/level/LevelList";
import { LevelShow } from "./pages/level/LevelShow";
import { LevelCreateEdit } from "./pages/level/LevelCreateEdit";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { fr } from "date-fns/locale/fr";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import { AdvertisementList } from "./pages/advertisement/AdvertisementList";
import { AdvertisementShow } from "./pages/advertisement/AdvertisementShow";
import { AdvertisementCreateEdit } from "./pages/advertisement/AdvertisementCreateEdit";
import { TranslationList } from "./pages/translation/TranslationList";
import HintsEdit from "./pages/hints/HintsEdit";
import HintsShow from "./pages/hints/HintsShow";
import QuestionsShow from "./pages/questions/QuestionsShow";
import QuestionsEdit from "./pages/questions/QuestionsEdit";

const App = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
  axios.defaults.headers.common.language = "fr";

  const { t, i18n } = useTranslation();
  const i18nProvider = {
    translate: (key: string, params: string) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
      <BrowserRouter>
        <RefineKbarProvider>
          <ColorModeContextProvider>
            <CssBaseline />
            <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
            <RefineSnackbarProvider>
              <Refine
                notificationProvider={notificationProvider}
                routerProvider={routerBindings}
                authProvider={authProvider}
                i18nProvider={i18nProvider}
                dataProvider={dataProvider(
                  process.env.REACT_APP_BACKEND_URL as string,
                  // @ts-ignore
                  axios
                )}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                  projectId: "R3cdLL-jW6N3k-NlYSeq",
                }}
                resources={[
                  {
                    name: "users",
                    list: "/users",
                    create: "/users/create",
                    show: "/users/show/:id",
                    edit: "/users/edit/:id",
                    meta: { icon: <PersonIcon /> },
                  },
                  {
                    name: "cryptors",
                    list: "/cryptors",
                    create: "/cryptors/create",
                    show: "/cryptors/show/:id",
                    edit: "/cryptors/edit/:id",
                    meta: { icon: <BookIcon /> },
                  },
                  {
                    name: "cities",
                    list: "/cities",
                    create: "/cities/create",
                    show: "/cities/show/:id",
                    edit: "/cities/edit/:id",
                    meta: { icon: <LocationCityIcon /> },
                  },
                  {
                    name: "faq",
                    list: "/faq",
                    create: "/faq/create",
                    show: "/faq/show/:id",
                    edit: "/faq/edit/:id",
                    meta: { icon: <HelpIcon /> },
                  },
                  {
                    name: "avatars",
                    list: "/avatars",
                    create: "/avatars/create",
                    show: "/avatars/show/:id",
                    edit: "/avatars/edit/:id",
                    meta: { icon: <FaceIcon /> },
                  },
                  {
                    name: "chat",
                    list: "/cryptors/:cryptorId/chat-message",
                    create: "/cryptors/:cryptorId/chat-message/create",
                    show: "/cryptors/:cryptorId/chat-message/:id",
                    edit: "/cryptors/:cryptorId/chat-message/edit/:id",
                    meta: { hide: true },
                  },
                  {
                    name: "levels",
                    list: "/levels",
                    create: "/levels/create",
                    show: "/levels/show/:id",
                    edit: "/levels/edit/:id",
                    meta: { icon: <LibraryBooksIcon /> },
                  },
                  {
                    name: "advertisements",
                    list: "/advertisements",
                    create: "/advertisements/create",
                    show: "/advertisements/show/:id",
                    edit: "/advertisements/edit/:id",
                    meta: { icon: <AdsClickIcon /> },
                  },
                  {
                    name: "translation",
                    list: "/translation",
                    meta: { icon: <TranslateIcon /> },
                  },
                ]}
              >
                <Routes>
                  <Route
                    element={
                      <Authenticated key="login" redirectOnFail="/login">
                        <ThemedLayoutV2 Header={Header} Title={Title}>
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                  >
                    <Route
                      path="/"
                      element={<Navigate to="/users" replace />}
                    />

                    <Route path="/users" element={<UserList />} />
                    <Route path="/users/edit/:id" element={<UserEdit />} />
                    <Route path="/users/show/:id" element={<UserShow />} />
                    <Route path="/users/create" element={<UserCreate />} />

                    <Route path="/cryptors" element={<CryptorList />} />
                    <Route
                      path="/cryptors/edit/:id"
                      element={<CryptorEdit />}
                    />
                    <Route
                      path="/cryptors/show/:id"
                      element={<CryptorShow />}
                    />
                    <Route
                      path="/cryptors/create"
                      element={<CryptorCreate />}
                    />

                    <Route path="/hints/edit" element={<HintsEdit />} />
                    <Route path="/hints/show" element={<HintsShow />} />

                    <Route path="/questions/edit" element={<QuestionsEdit />} />
                    <Route path="/questions/show" element={<QuestionsShow />} />

                    <Route path="/faq" element={<FaqList />} />
                    <Route path="/faq/create" element={<FaqCreate />} />
                    <Route path="/faq/show/:id" element={<FaqShow />} />
                    <Route path="/faq/edit/:id" element={<FaqEdit />} />

                    <Route path="/cities" element={<CitiesList />} />
                    <Route path="/cities/create" element={<CitiesCreate />} />
                    <Route path="/cities/show/:id" element={<CitiesShow />} />
                    <Route path="/cities/edit/:id" element={<CitiesEdit />} />

                    <Route path="/avatars" element={<AvatarList />} />
                    <Route path="/avatars/create" element={<AvatarCreate />} />
                    <Route path="/avatars/show/:id" element={<AvatarShow />} />
                    <Route path="/avatars/edit/:id" element={<AvatarEdit />} />

                    <Route path="/levels" element={<LevelList />} />
                    <Route path="/levels/show/:id" element={<LevelShow />} />
                    <Route
                      path="/levels/create"
                      element={<LevelCreateEdit />}
                    />
                    <Route
                      path="/levels/edit/:id"
                      element={<LevelCreateEdit isEditing />}
                    />

                    <Route
                      path="/advertisements"
                      element={<AdvertisementList />}
                    />
                    <Route
                      path="/advertisements/show/:id"
                      element={<AdvertisementShow />}
                    />
                    <Route
                      path="/advertisements/create"
                      element={<AdvertisementCreateEdit />}
                    />
                    <Route
                      path="/advertisements/edit/:id"
                      element={<AdvertisementCreateEdit />}
                    />

                    <Route
                      path="/cryptors/:cryptorId/chat-message"
                      element={<ChatMessageList />}
                    />
                    <Route
                      path="/cryptors/:cryptorId/chat-message/create"
                      element={<ChatMessageCreate />}
                    />
                    <Route
                      path="/cryptors/:cryptorId/chat-message/edit/:id"
                      element={<ChatMessageEdit />}
                    />
                    <Route
                      path="/cryptors/:cryptorId/chat-message/:id"
                      element={<ChatMessageShow />}
                    />

                    <Route path="/translation" element={<TranslationList />} />

                    <Route path="*" element={<ErrorComponent />} />
                  </Route>
                  <Route
                    path="login"
                    element={
                      <AuthPage
                        type="login"
                        title="Cryptors"
                        registerLink={false}
                      />
                    }
                  />
                </Routes>
                <RefineKbar />
                <UnsavedChangesNotifier />
                <DocumentTitleHandler />
              </Refine>
            </RefineSnackbarProvider>
          </ColorModeContextProvider>
        </RefineKbarProvider>
      </BrowserRouter>
    </LocalizationProvider>
  );
};

export default App;
