import { Box, IconButton, TextField, Divider, Button } from "@mui/material";

import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Control, UseFormRegister, useFieldArray } from "react-hook-form";
import { CryptorFormData } from "../utils/cryptor.schema";

interface CryptorCorpusSectionProps {
  control: Control<CryptorFormData, {}>;
  register: UseFormRegister<CryptorFormData>;
}

const CryptorCorpusSection: FC<CryptorCorpusSectionProps> = ({
  control,
  register,
}) => {
  const {
    append: appendCorpus,
    remove: removeCorpus,
    fields: corpus,
  } = useFieldArray({
    control,
    name: "corpus",
  });
  return (
    <>
      <Box display="flex" flexDirection="column" gap={3}>
        {corpus.map((field, index) => (
          <Box key={field.id}>
            <IconButton
              onClick={() => removeCorpus(index)}
              sx={{ float: "right" }}
            >
              <CloseIcon />
            </IconButton>
            <TextField
              {...register(`corpus.${index}.content`)}
              multiline
              rows={10}
              sx={{ width: "100%" }}
            />
          </Box>
        ))}
      </Box>

      <Divider sx={{ marginTop: 3 }}>
        <Button onClick={() => appendCorpus({ content: "" })}>
          Ajouter un corpus
        </Button>
      </Divider>
    </>
  );
};

export default CryptorCorpusSection;
