import { useShow, IResourceComponentsProps, useMany } from "@refinedev/core";
import {
  Show,
  NumberField,
  TextFieldComponent as TextField,
  EmailField,
  BooleanField,
  DateField,
} from "@refinedev/mui";
import { Typography, Stack, CircularProgress } from "@mui/material";
import { Cryptor } from "../../../../backend/shared/types/cryptors/cryptors.type";
import { User } from "../../../../backend/shared/types/users/user.type";

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<User>();
  const { data: cryptorsData } = useMany<Cryptor>({
    resource: "cryptors",
    ids: [],
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Id
        </Typography>
        <NumberField value={record?.id ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Pseudo
        </Typography>
        <TextField value={record?.pseudo} />
        <Typography variant="body1" fontWeight="bold">
          Email
        </Typography>
        <EmailField value={record?.email} />
        <Typography variant="body1" fontWeight="bold">
          Email confirmé
        </Typography>
        <BooleanField value={record?.emailConfirmed} />
        <Typography variant="body1" fontWeight="bold">
          Role
        </Typography>
        <TextField value={record?.role} />
        <Typography variant="body1" fontWeight="bold">
          Avatar
        </Typography>
        <img src={record?.avatar?.image?.url} alt="Avatar" width="100" />
        <Typography variant="body1" fontWeight="bold">
          Date de création
        </Typography>
        <DateField value={record?.createdAt} />
        <Typography variant="body1" fontWeight="bold">
          Score
        </Typography>
        <NumberField value={record?.score ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Rang
        </Typography>
        <NumberField value={record?.rank ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Acces aux cryptors en brouillon
        </Typography>
        {cryptorsData?.data ? (
          <TextField
            value={record?.cryptorsDraftAccess
              .map(
                (el) =>
                  cryptorsData.data.find((cryptor) => cryptor.id === el.id)
                    ?.name
              )
              .join(", ")}
          />
        ) : (
          <CircularProgress />
        )}
      </Stack>
    </Show>
  );
};
