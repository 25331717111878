import { FC } from "react";
import { TextField } from "@mui/material";
import { UseFormRegister } from "react-hook-form";
import { CryptorFormData } from "../utils/cryptor.schema";

interface CryptorNameTextFieldProps {
  register: UseFormRegister<CryptorFormData>;
}

const CryptorNameTextField: FC<CryptorNameTextFieldProps> = ({ register }) => {
  return (
    <TextField
      {...register("name", {
        required: "Ce champ est requis",
      })}
      defaultValue={null}
      margin="normal"
      fullWidth
      InputLabelProps={{ shrink: true }}
      type="text"
      label="Nom"
      name="name"
    />
  );
};

export default CryptorNameTextField;
