import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useDeleteQuestion,
  useGetQuestions,
  usePatchQuestion,
  usePostQuestion,
} from "../../api/questions.api";
import { Edit } from "@refinedev/mui";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { getTranslation } from "../../utils/translation";
import DeleteIcon from "@mui/icons-material/Delete";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import UploadField from "../../components/forms/UploadField";
import { ArrowBack, Close } from "@mui/icons-material";

export const QuestionsSchema = z.object({
  questions: z.array(
    z.object({
      id: z.string().nullable(),
      textTranslation: z.object({ value: z.string().min(1).optional() }),
      reward: z.coerce.number().min(0),
      index: z.coerce.number().min(0),
      cryptorId: z.number().min(0),
      propositions: z.array(
        z.object({
          id: z.string().optional(),
          textTranslation: z.object({ value: z.string() }),
          isCorrect: z.boolean(),
        })
      ),
      answerDetails: z
        .object({
          id: z.number().optional(),
          descriptionTranslation: z.object({ value: z.string() }),
          image: z.object({ url: z.string().min(1).nullable() }).nullable(),
        })
        .nullable(),
    })
  ),
});

export type QuestionsFormData = z.infer<typeof QuestionsSchema>;

const QuestionsEdit = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const cryptorId = searchParams.get("cryptorId");

  const { data, isLoading } = useGetQuestions(Number(cryptorId));
  const { mutateAsync: postQuestion } = usePostQuestion();
  const { mutateAsync: patchQuestion } = usePatchQuestion();
  const { mutateAsync: deleteQuestion } = useDeleteQuestion();

  const formatedData = useMemo(
    () => ({
      questions: data.map((question) => ({
        id: question.id,
        textTranslation: {
          value: getTranslation(question.textTranslation)?.value || "",
        },
        cryptorId: Number(cryptorId),
        reward: question.reward || 0,
        index: question.index || 0,
        propositions: question.propositions.map((proposition) => ({
          id: proposition.id,
          textTranslation: {
            value: getTranslation(proposition.textTranslation)?.value || "",
          },
          isCorrect: proposition.isCorrect,
        })),
        answerDetails: question.answerDetails
          ? {
              id: question.answerDetails.id,
              descriptionTranslation: {
                value:
                  getTranslation(question.answerDetails.descriptionTranslation)
                    ?.value || "",
              },
              image: question.answerDetails.image,
            }
          : {
              id: undefined,
              descriptionTranslation: { value: "" },
              image: null,
            },
      })),
    }),
    [data, cryptorId]
  );

  const {
    reset,
    handleSubmit,
    formState: { isValid, errors },
    control,
    watch,
    setError,
    setValue,
  } = useForm<QuestionsFormData>({
    resolver: zodResolver(QuestionsSchema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  useEffect(() => {
    reset(formatedData);
  }, [reset, formatedData]);

  const questions = watch("questions");

  if (!questions) return null;

  return (
    <Edit
      goBack={
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
      }
      headerButtons={() => null}
      isLoading={isLoading}
      saveButtonProps={{
        onClick: handleSubmit((data) => {
          for (const question of data.questions) {
            const { id, answerDetails, ...questionWithoutId } = question;
            const answerDetailsFormated = answerDetails
              ? {
                  id: answerDetails.id,
                  descriptionTranslation: answerDetails.descriptionTranslation,
                  image:
                    answerDetails.image && answerDetails.image.url
                      ? { url: answerDetails.image.url }
                      : null,
                }
              : null;

            if (id) {
              patchQuestion(
                {
                  id,
                  data: {
                    ...questionWithoutId,
                    answerDetails: answerDetailsFormated,
                  },
                },
                { onSuccess: () => navigate(0) }
              );
            } else {
              postQuestion(
                {
                  ...questionWithoutId,
                  answerDetails: answerDetailsFormated,
                },
                { onSuccess: () => navigate(0) }
              );
            }
          }
        }),
        disabled: !isValid,
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        {questions.map((question, index) => (
          <Box key={question.id}>
            <Box display="flex" flexDirection="row" gap={3}>
              <Box marginY={2}>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    const { id } = question;
                    if (id) {
                      deleteQuestion(id);
                    } else {
                      reset({
                        questions: questions.filter((el, i) => i !== index),
                      });
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box display="flex" flexDirection="column" flex={1}>
                <Box display="flex" flexDirection="row" gap={3}>
                  <Box display="flex" flexDirection="column" flex={1}>
                    <Controller
                      key={question.id}
                      name={`questions.${index}.id`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextField
                            key={question.id}
                            {...field}
                            margin="normal"
                            label="Id"
                            disabled
                          />
                        );
                      }}
                    />
                    <Controller
                      key={question.id}
                      name={`questions.${index}.textTranslation.value`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextField
                            key={question.id}
                            {...field}
                            margin="normal"
                            label="Question"
                            multiline
                          />
                        );
                      }}
                    />
                    {question.propositions.map((proposition, pIndex) => {
                      return (
                        <Stack
                          key={"question.propositions" + proposition.id}
                          direction="row"
                          alignItems="center"
                          gap={3}
                        >
                          <Controller
                            key={question.id}
                            name={`questions.${index}.propositions.${pIndex}.textTranslation.value`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <TextField
                                  key={question.id}
                                  {...field}
                                  margin="normal"
                                  label={`Réponse ${index + 1}`}
                                  style={{ flex: 1 }}
                                />
                              );
                            }}
                          />
                          <Controller
                            control={control}
                            name={`questions.${index}.propositions.${pIndex}.isCorrect`}
                            render={({ field }) => {
                              return (
                                <FormControlLabel
                                  checked={field.value || false}
                                  onChange={field.onChange}
                                  onBlur={field.onBlur}
                                  control={<Checkbox />}
                                  label="Est correct"
                                  sx={{ minWidth: 125 }}
                                />
                              );
                            }}
                          />
                          <IconButton
                            onClick={() => {
                              setValue(
                                `questions.${index}.propositions`,
                                question.propositions.filter(
                                  (el, i) => i !== pIndex
                                )
                              );
                            }}
                          >
                            <Close />
                          </IconButton>
                        </Stack>
                      );
                    })}
                    <Button
                      onClick={() => {
                        setValue(`questions.${index}.propositions`, [
                          ...question.propositions,
                          {
                            id: undefined,
                            textTranslation: { value: "" },
                            isCorrect: false,
                          },
                        ]);
                      }}
                    >
                      Ajouter une proposition
                    </Button>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Controller
                      key={question.id}
                      name={`questions.${index}.reward`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextField
                            key={question.id}
                            {...field}
                            margin="normal"
                            label="Récompense"
                            onChange={(event) =>
                              field.onChange(Number(event.target.value))
                            }
                          />
                        );
                      }}
                    />
                    <Controller
                      key={question.id}
                      name={`questions.${index}.index`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextField
                            key={question.id}
                            {...field}
                            margin="normal"
                            label="Index"
                            onChange={(event) =>
                              field.onChange(Number(event.target.value))
                            }
                          />
                        );
                      }}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" gap={3}>
                  <Box display="flex" flexDirection="column" flex={1}>
                    <Controller
                      key={question.id}
                      name={`questions.${index}.answerDetails.descriptionTranslation.value`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextField
                            key={question.id}
                            {...field}
                            margin="normal"
                            label="Detail de la bonne réponse"
                            multiline
                            minRows={3}
                          />
                        );
                      }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <UploadField
                      name={`questions.${index}.answerDetails.image.url`}
                      uploadButtonLabel="Upload image"
                      s3FoldersType="questions"
                      control={control as any}
                      setError={setError as any}
                      errorMessage={
                        (errors as any)?.questions?.[index]?.answerDetails
                          ?.image?.url
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Divider sx={{ marginTop: 3 }} />
          </Box>
        ))}
        <Divider sx={{ marginTop: 3 }}>
          <Button
            onClick={() => {
              reset({
                questions: [
                  ...questions,
                  {
                    id: null,
                    textTranslation: { value: "" },
                    cryptorId: Number(cryptorId),
                    reward: 0,
                    index: questions.length + 1,
                    propositions: [],
                    answerDetails: {
                      id: undefined,
                      descriptionTranslation: { value: "" },
                      image: null,
                    },
                  },
                ],
              });
            }}
          >
            Ajouter une question
          </Button>
        </Divider>
      </Box>
    </Edit>
  );
};

export default QuestionsEdit;
