import React, { FC, useEffect, useRef } from "react";

interface AudioPlayerProps {
  url: string;
}

const AudioPlayer: FC<AudioPlayerProps> = ({ url }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  useEffect(() => {
    if (url && audioRef.current) {
      const audio = audioRef.current;
      audio.src = url;
      audio.load();
    }
  }, [url]);

  return (
    <audio controls ref={audioRef}>
      <source src={url} />
    </audio>
  );
};

export default AudioPlayer;
