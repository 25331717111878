import { FC } from "react";
import { useGo } from "@refinedev/core";
import { IconButton, Stack, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useParams } from "react-router-dom";

interface ChatMessageChildrenShowProps {
  chatMessageChildrenId: number;
  showLabel?: boolean;
  label?: string;
}

const ChatMessageChildrenShow: FC<ChatMessageChildrenShowProps> = ({
  chatMessageChildrenId,
  showLabel = true,
  label = "Enfant",
}) => {
  const { cryptorId } = useParams();
  const go = useGo();
  return (
    <Stack marginTop={1} marginBottom={1} direction="row" alignItems="center">
      {showLabel && (
        <Typography variant="body1" fontWeight="bold">
          {label}
        </Typography>
      )}
      <IconButton
        onClick={() => {
          go({
            to: `/cryptors/${cryptorId}/chat-message/${chatMessageChildrenId}`,
          });
        }}
      >
        <OpenInNewIcon />
      </IconButton>
    </Stack>
  );
};

export default ChatMessageChildrenShow;
