import { useShow, IResourceComponentsProps } from "@refinedev/core";
import {
  Show,
  NumberField,
  TextFieldComponent as TextField,
} from "@refinedev/mui";
import { Typography, Stack, Box } from "@mui/material";
import { Level } from "shared";
import { getTranslation } from "../../utils/translation";
import SelectLanguage from "../../components/miscellaneous/SelectLanguage";
import i18n from "../../i18n";

export const LevelShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data as Level;

  const dataFormated = {
    ...record,
    name: getTranslation(record?.nameTranslation, i18n.language)?.value,
  };

  return (
    <Show
      isLoading={isLoading}
      headerButtons={({ defaultButtons }) => (
        <Box display="flex" alignItems="center" gap={1}>
          {defaultButtons}
          <SelectLanguage />
        </Box>
      )}
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Niveau
        </Typography>
        <NumberField value={dataFormated?.level ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Nom
        </Typography>
        <TextField value={dataFormated?.name ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Points requis
        </Typography>
        <NumberField value={dataFormated?.pointsRequired ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Badge
        </Typography>
        <img src={dataFormated?.image?.url} alt="Cryptor" width="100" />
      </Stack>
    </Show>
  );
};
