import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { City } from "shared";

const getCities = async () =>
  axios.get<City[]>("/cities").then((res) => res.data);

export const useGetCities = () =>
  useQuery({
    initialData: [],
    queryKey: ["cities"],
    queryFn: getCities,
  });
