import { TextField } from "@mui/material";
import { FC } from "react";
import { UseFormRegister } from "react-hook-form";
import { CryptorFormData } from "../utils/cryptor.schema";

interface CryptorRewardFieldProps {
  register: UseFormRegister<CryptorFormData>;
}

const CryptorRewardField: FC<CryptorRewardFieldProps> = ({ register }) => {
  return (
    <TextField
      {...register("reward", {
        required: "Ce champ est requis",
        valueAsNumber: true,
      })}
      defaultValue={null}
      margin="normal"
      fullWidth
      InputLabelProps={{ shrink: true }}
      type="number"
      label="Récompense"
      name="reward"
    />
  );
};

export default CryptorRewardField;
