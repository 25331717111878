import { useShow, IResourceComponentsProps, useMany } from "@refinedev/core";
import { Show, TextFieldComponent as TextField } from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";
import { Advertisement, Cryptor } from "shared";

export const AdvertisementShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const { data: cryptors } = useMany<Cryptor>({
    resource: "cryptors",
    ids: [],
  });

  const record = data?.data as Advertisement;

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Nom
        </Typography>
        <TextField value={record?.url ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Image
        </Typography>
        <img src={record?.image?.url} alt="Cryptor" width="100" />
        <Typography variant="body1" fontWeight="bold">
          Cryptors
        </Typography>
        <Stack gap={1}>
          {cryptors?.data
            .filter((cryptor) =>
              record?.cryptors.some((c) => c.id === cryptor.id)
            )
            .map((cryptor) => (
              <Typography key={cryptor.id}>{cryptor.name}</Typography>
            ))}
        </Stack>
        <Typography variant="body1" fontWeight="bold">
          Cryptors Discussion
        </Typography>
        <Stack gap={1}>
          {cryptors?.data
            .filter((cryptor) =>
              record?.cryptorsDiscussion.some((c) => c.id === cryptor.id)
            )
            .map((cryptor) => (
              <Typography key={cryptor.id}>{cryptor.name}</Typography>
            ))}
        </Stack>
      </Stack>
    </Show>
  );
};
