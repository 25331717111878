import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Show, TextFieldComponent as TextField } from "@refinedev/mui";
import { getTranslation } from "../../utils/translation";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import { Fragment } from "react";
import { useGetQuestions } from "../../api/questions.api";
import { Close, Done } from "@mui/icons-material";

const QuestionsShow = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const cryptorId = searchParams.get("cryptorId");

  const { data: questions, isLoading } = useGetQuestions(Number(cryptorId));

  return (
    <Show
      isLoading={isLoading}
      goBack={
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
      }
      headerButtons={() => (
        <Stack gap={1} display="flex" alignItems="center">
          <LoadingButton
            onClick={() => {
              navigate(`/questions/edit?cryptorId=${cryptorId}`);
              navigate(0);
            }}
          >
            <Box display="flex" gap={0.5}>
              <EditIcon />
              Modifier
            </Box>
          </LoadingButton>
        </Stack>
      )}
    >
      <Stack gap={1}>
        {questions.map((question) => (
          <Fragment key={question.id}>
            <Typography variant="body1" fontWeight="bold">
              Id
            </Typography>
            <TextField value={question.id} />
            <Typography variant="body1" fontWeight="bold">
              Index
            </Typography>
            <TextField value={question.index} />
            <Typography variant="body1" fontWeight="bold">
              Récompense
            </Typography>
            <TextField value={question.reward || 0} />
            {question?.textTranslation && (
              <>
                <Typography variant="body1" fontWeight="bold">
                  Question
                </Typography>
                <TextField
                  value={getTranslation(question?.textTranslation)?.value}
                />
              </>
            )}
            {question.propositions.length > 0 && (
              <>
                <Typography variant="body1" fontWeight="bold">
                  Propositions
                </Typography>
                {question.propositions?.map((proposition) => (
                  <Stack direction="row" alignItems="center">
                    {proposition.isCorrect ? <Done /> : <Close />}
                    <TextField
                      value={getTranslation(proposition.textTranslation)?.value}
                      sx={{ marginRight: 2 }}
                    />
                  </Stack>
                ))}
              </>
            )}
            {question.answerDetails && (
              <>
                <Typography variant="body1" fontWeight="bold">
                  Détail de la réponse
                </Typography>
                {getTranslation(question.answerDetails.descriptionTranslation)
                  ?.value && (
                  <TextField
                    value={
                      getTranslation(
                        question.answerDetails.descriptionTranslation
                      )?.value
                    }
                  />
                )}
                {question.answerDetails.image?.url && (
                  <img
                    src={question.answerDetails.image?.url}
                    alt="Indice"
                    width="100"
                  />
                )}
              </>
            )}

            <Divider sx={{ marginTop: 3 }} />
          </Fragment>
        ))}
      </Stack>
    </Show>
  );
};

export default QuestionsShow;
