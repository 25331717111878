import { useShow, IResourceComponentsProps } from "@refinedev/core";
import { Show, NumberField, DateField } from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

export const AvatarShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Id
        </Typography>
        <NumberField value={record?.id ?? ""} />
        <Typography variant="body1" fontWeight="bold">
          Image
        </Typography>
        <img src={record?.image?.url} alt="Avatar" width="100" />
        <Typography variant="body1" fontWeight="bold">
          Date de création
        </Typography>
        <DateField value={record?.createdAt} />
      </Stack>
    </Show>
  );
};
